@charset "UTF-8";
/*
OZ RESET CSS
Author: ONZE
Author URI: http://on-ze.com
Version: 1.1
License: GNU General Public License
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/
* {
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  font: inherit;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  text-decoration: none;
  vertical-align: baseline; }

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%; }

body {
  font-size: 10px;
  font-size: 1.0rem;
  line-height: 1;
  position: relative;
  text-align: center;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%; }

article, aside, dialog, figure, footer, header, main, menu, nav, section {
  display: block; }

audio, canvas, video {
  display: inline-block; }

hr {
  display: block; }

ol,
ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

input,
select {
  vertical-align: middle; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  empty-cells: show; }

img {
  vertical-align: bottom; }

body {
  color: #333;
  font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 1.0rem;
  text-align: left;
  line-height: 1.8; }

.pc_view {
  display: none !important; }

img {
  width: 100%; }

a {
  color: #124985;
  transition: all 0.3s ease-out; }

strong {
  color: #cd1818;
  font-weight: bold; }

em {
  font-weight: bold; }

#wrap {
  font-size: 1.2rem; }

/* icon font
----------------------------------------------------------- */
@font-face {
  font-family: 'icomoon';
  src: url("/common/icon/fonts/icomoon.eot?2ubw10");
  src: url("/common/icon/fonts/icomoon.eot?2ubw10#iefix") format("embedded-opentype"), url("/common/icon/fonts/icomoon.woff2?2ubw10") format("woff2"), url("/common/icon/fonts/icomoon.ttf?2ubw10") format("truetype"), url("/common/icon/fonts/icomoon.woff?2ubw10") format("woff"), url("/common/icon/fonts/icomoon.svg?2ubw10#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-freedial .path1:before {
  content: "\e902";
  color: black; }

.icon-freedial .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: black; }

.icon-freedial .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: black; }

.icon-freedial .path4:before {
  content: "\e909";
  margin-left: -1em;
  color: white; }

.icon-envelope:before {
  content: "\e906"; }

.icon-monitor:before {
  content: "\e900"; }

.icon-document:before {
  content: "\e901"; }

.icon-worry:before {
  content: "\e903"; }

.icon-truck:before {
  content: "\e904"; }

.icon-reassurance:before {
  content: "\e905"; }

.icon-th-large:before {
  content: "\f009"; }

.icon-chevron-left:before {
  content: "\f053"; }

.icon-chevron-right:before {
  content: "\f054"; }

.icon-plus:before {
  content: "\f067"; }

.icon-minus:before {
  content: "\f068"; }

.icon-chevron-up:before {
  content: "\f077"; }

.icon-chevron-down:before {
  content: "\f078"; }

.icon-shopping-cart:before {
  content: "\f07a"; }

.icon-caret-down:before {
  content: "\f0d7"; }

.icon-caret-up:before {
  content: "\f0d8"; }

.icon-caret-left:before {
  content: "\f0d9"; }

.icon-caret-right:before {
  content: "\f0da"; }

.icon-angle-left:before {
  content: "\f104"; }

.icon-angle-right:before {
  content: "\f105"; }

.icon-angle-up:before {
  content: "\f106"; }

.icon-angle-down:before {
  content: "\f107"; }

/* ============================================================ */
/* header */
/* ============================================================ */
header {
  margin: 1px 0 0;
  border-top: 2px solid #3877bd; }
  header .inner {
    display: flex;
    align-items: center;
    margin: 10px; }
    header .inner #header_logo {
      flex: 1;
      color: #084385;
      font-size: 1.7rem;
      font-weight: bold; }
    header .inner > div:not(#header_logo) {
      width: 120px; }

/* ============================================================ */
/* #global_nav */
/* ============================================================ */
#global_nav p {
  padding: 5px 0;
  background: linear-gradient(to bottom, #3877bd 0%, #2e68aa 49%, #2561a3 50%, #2161a9 100%);
  border: 1px solid #195190;
  color: #fff;
  text-align: center; }

#global_nav div {
  display: none;
  padding: 10px;
  background: #1f5da2; }
  #global_nav div ul {
    display: flex;
    flex-wrap: wrap; }
    #global_nav div ul li {
      width: 50%; }
      #global_nav div ul li a {
        display: block;
        padding: 10px 0;
        background: linear-gradient(to bottom, #fff 0%, #ededed 100%);
        border-left: 1px solid #d4d4d4;
        border-bottom: 1px solid #d4d4d4;
        box-shadow: inset 1px 0 0 white;
        color: #333;
        text-align: center; }
      #global_nav div ul li:nth-child(-n+2) a {
        border-top: 1px solid #d4d4d4; }
      #global_nav div ul li:nth-child(2n) a {
        border-right: 1px solid #d4d4d4; }
      #global_nav div ul li:last-child a {
        background: linear-gradient(to bottom, #ed3f3f 0%, #ed3f3f 49%, #dd2b2b 50%, #cd1818 100%);
        border-bottom: 1px solid #cd1818;
        border-right: none;
        box-shadow: none;
        color: #fff; }

/* ============================================================ */
/* #contents */
/* ============================================================ */
#main {
  margin: 10px 10px 30px; }

.text_red {
  color: #cd1818; }

hr {
  margin: 15px 0;
  border-top: 1px solid #e2e1e1; }

/* #topic_path
----------------------------------------------------------- */
#topic_path {
  margin: 10px;
  font-size: 1.0rem; }
  #topic_path ul {
    display: flex;
    flex-wrap: wrap; }
    #topic_path ul li:not(:last-child)::after {
      content: ">";
      margin: 0 5px; }

/* ============================================================ */
/* #main */
/* ============================================================ */
/* ttl
----------------------------------------------------------- */
/*----- .ttl01 -----*/
.ttl01 {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 64px;
  margin: 0 0 15px;
  padding: 0 15px;
  background: url(/common/img/common/bg_ttl01.png) right bottom no-repeat #fcfafa;
  background-size: 35%;
  border: 1px solid #d4d4d4;
  box-shadow: inset 1px 1px 0 white, inset -1px 0 0 white;
  border-bottom: none;
  color: #084385;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.2; }
  .ttl01::before, .ttl01::after {
    position: absolute;
    left: 0;
    bottom: 0;
    display: block;
    content: "";
    height: 2px;
    background: #f90; }
  .ttl01::before {
    width: 100%;
    background: #7ba8d9; }
  .ttl01::after {
    width: 250px;
    background: #2f6eb4; }
  .ttl01 small {
    display: block;
    margin: 0 0 2px;
    font-size: 1.1rem; }

/*----- .ttl02 -----*/
.ttl02 {
  position: relative;
  margin: 0 0 15px;
  padding: 3px 0 8px 30px;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.2; }
  .ttl02::before {
    position: absolute;
    left: 5px;
    top: 2px;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\e906";
    color: #2f6eb4;
    font-size: 2.2rem; }
  .ttl02::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    content: "";
    height: 1px;
    background: #dcdcdc;
    box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05); }

/*----- .ttl03 -----*/
.ttl03 {
  position: relative;
  margin: 0 0 15px;
  padding: 8px 20px 8px 24px;
  background: #f3f9fd;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.4; }
  .ttl03::before {
    position: absolute;
    left: 10px;
    top: 8px;
    bottom: 8px;
    content: "";
    width: 4px;
    background: linear-gradient(to bottom, #3877bd 0%, #1e5ca1 100%);
    border-radius: 2px; }

/*----- .ttl04 -----*/
.ttl04 {
  position: relative;
  margin: 0 0 15px;
  padding: 0 0 8px 20px;
  border-bottom: 1px dotted #dcdcdc;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.4; }
  .ttl04::before {
    position: absolute;
    left: 3px;
    top: 3px;
    display: block;
    content: "";
    width: 12px;
    height: 12px;
    border: 4px solid #205ea3;
    border-radius: 50%;
    box-sizing: border-box; }

/* ブロック
--------------------------------------------------------------- */
.row:not(:last-child) {
  margin: 0 0 30px; }
  .row:not(:last-child)::after {
    display: block;
    content: "";
    clear: both; }
  .row:not(:last-child) p:not(:last-child) {
    margin: 0 0 10px; }

/* 左右寄せ／センタリング
--------------------------------------------------------------- */
.al_left {
  text-align: left;
  display: block; }

.al_right {
  text-align: right;
  display: block; }

.al_center {
  text-align: center;
  display: block; }

/* 本文文字サイズ
--------------------------------------------------------------- */
.text_ex_large {
  font-size: 1.6rem; }

.text_large {
  font-size: 1.4rem; }

.text_middle {
  font-size: 1.3rem; }

.text_small {
  font-size: 1.0rem; }

/* リストスタイル
----------------------------------------------------------- */
.list_disc {
  list-style: disc;
  margin: 0 0 0 25px; }
  .list_disc > li:not(:first-child) {
    margin: 10px 0 0; }

.list_note {
  display: table; }
  .list_note > li {
    display: table-row; }
    .list_note > li > *:first-child {
      display: table-cell; }

/* テーブル
--------------------------------------------------------------- */
table {
  width: 100%; }
  table th,
  table td {
    padding: 6px 10px;
    border: 1px solid #dcdcdc;
    vertical-align: middle;
    line-height: 1.6; }
  table th {
    background: #ddedff;
    text-align: center; }

.table th,
.table td {
  padding: 10px 15px;
  text-align: left; }

.table th {
  text-align: left;
  width: 25%; }

.form_table {
  margin: 0 0 25px; }
  .form_table tr,
  .form_table th,
  .form_table td {
    display: block; }
  .form_table th,
  .form_table td {
    padding: 10px 15px;
    border: none;
    border-bottom: 1px dotted #dcdcdc;
    text-align: left; }
  .form_table th {
    background: #2f6eb4;
    color: #fff; }
  .form_table td {
    padding: 15px 5px 30px; }

/* input[type="text"]
----------------------------------------------------------- */
input[type="text"] {
  width: 100%;
  padding: 5px;
  background: #f8f8f8;
  border: 1px solid #dadada;
  border-radius: 0;
  appearance: none;
  font-size: 1.6rem; }
  input[type="text"]:-webkit-autofill {
    box-shadow: 0 0 0 1000px #fff inset; }
  input[type="text"]:focus {
    background: #fff; }

.has_error input[type="text"] {
  background: #fff1f1;
  border: 1px solid #f9bebe; }
  .has_error input[type="text"]:-webkit-autofill {
    box-shadow: 0 0 0 1000px #fff1f1 inset; }
  .has_error input[type="text"]:focus {
    background: #fff; }

/* textarea
----------------------------------------------------------- */
textarea {
  width: 100%;
  height: 150px;
  padding: 5px;
  background: #f8f8f8;
  border: 1px solid #dadada;
  border-radius: 0;
  font-size: 1.6rem;
  appearance: none; }
  textarea:focus {
    background: #fff; }

.has_error textarea {
  background: #fff1f1;
  border: 1px solid #f9bebe; }
  .has_error textarea:focus {
    background: #fff; }

/* select
----------------------------------------------------------- */
select {
  padding: 5px;
  border: 1px solid #dadada;
  font-size: 1.6rem; }

.has_error select {
  background: #fff1f1;
  border: 1px solid #f9bebe; }
  .has_error select:focus {
    background: #fff; }

/* .checkbox
----------------------------------------------------------- */
label.checkbox {
  position: relative;
  display: inline-block;
  margin: 0 20px 0 0;
  padding: 0 0 0 25px;
  overflow: hidden;
  cursor: pointer; }
  label.checkbox::before {
    position: absolute;
    top: 3px;
    left: 0;
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #dcdcdc;
    z-index: 3; }
  label.checkbox::after {
    position: absolute;
    top: 45%;
    left: 5px;
    display: block;
    content: "";
    width: 6px;
    height: 10px;
    margin-top: -8px;
    border-right: 3px solid #2f6eb4;
    border-bottom: 3px solid #2f6eb4;
    transform: rotate(45deg);
    z-index: 1; }
  label.checkbox input[type="checkbox"] {
    position: absolute;
    top: 4px;
    left: -40px;
    display: block;
    width: 16px;
    height: 16px;
    box-shadow: 41px 0 0 #fff;
    z-index: 2;
    appearance: none; }
    label.checkbox input[type="checkbox"]:checked {
      box-shadow: none; }

/* .radio
----------------------------------------------------------- */
.radio {
  position: relative;
  display: block;
  margin: 0 0 5px;
  padding: 0 0 0 25px;
  overflow: hidden;
  cursor: pointer; }
  .radio:before {
    position: absolute;
    top: 1px;
    left: 0;
    z-index: 3;
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid #b2b2b2;
    border-radius: 50%; }
  .radio:after {
    position: absolute;
    top: 4px;
    left: 3px;
    z-index: 1;
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #2f6eb4; }
  .radio input[type="radio"] {
    appearance: none;
    position: absolute;
    top: 0;
    left: -23px;
    z-index: 2;
    width: 20px;
    height: 20px;
    box-shadow: 20px -1px #fff; }
    .radio input[type="radio"]:checked {
      box-shadow: none; }
    .radio input[type="radio"]:focus {
      opacity: .2;
      box-shadow: 20px -1px #fff; }

/* .alert_block
----------------------------------------------------------- */
.alert_block {
  margin: 20px 0;
  padding: 10px 15px;
  background: #fffafa;
  border: 3px solid #ffc7c7; }

/* button
----------------------------------------------------------- */
.btn {
  display: inline-block;
  min-width: 150px;
  margin: 0 auto;
  padding: 10px 20px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  text-align: center;
  background: linear-gradient(to bottom, #fffefe 0%, #f6f6f7 49%, #ededed 50%, #ededed 100%);
  color: #333; }
  .btn i {
    color: #fd6601; }

.btn_blue {
  display: inline-block;
  min-width: 150px;
  margin: 0 auto;
  padding: 10px 20px;
  border: 1px solid #125290;
  border-radius: 4px;
  text-align: center;
  background: linear-gradient(to bottom, #4185c7 0%, #4085c7 49%, #2c72b4 50%, #195fa3 100%);
  color: #fff; }
  .btn_blue i {
    color: #fff; }

.btn_red {
  display: inline-block;
  min-width: 150px;
  margin: 0 auto;
  padding: 10px 20px;
  border: 1px solid #b71313;
  border-radius: 4px;
  text-align: center;
  background: linear-gradient(to bottom, #ed3f3f 0%, #e93b3b 49%, #dd2c2c 50%, #ce1919 100%);
  color: #fff; }
  .btn_red i {
    color: #fff; }

.btn_submit {
  display: inline-block;
  min-width: 150px;
  margin: 0 auto;
  padding: 10px 20px;
  border: 1px solid #dd630d;
  border-radius: 4px;
  text-align: center;
  background: linear-gradient(to bottom, rgba(255, 122, 28, 0.8) 0%, rgba(255, 122, 28, 0.8) 49%, rgba(253, 112, 12, 0.8) 50%, rgba(253, 112, 12, 0.8) 100%);
  background-color: #fd700c;
  color: #fff; }
  .btn_submit i {
    color: #fff; }
  .btn_submit:hover {
    background-color: #ce5c0c; }

[class^="btn"] i {
  margin: 0 5px 0 0; }

/* .anchor_link
----------------------------------------------------------- */
.anchor_link {
  margin: 0 0 20px; }
  .anchor_link ul {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.4; }
    .anchor_link ul li {
      display: flex; }
      .anchor_link ul li a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 10px 0 15px;
        background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0) 100%);
        background-color: #eee;
        border: 1px solid #dcdcdc;
        border-left: none;
        box-shadow: inset 1px 0 0 white;
        color: #333;
        text-align: center; }
        .anchor_link ul li a i {
          position: absolute;
          bottom: 2px;
          left: 50%;
          color: #2f6eb4;
          font-size: 1.0rem;
          line-height: 1; }
      .anchor_link ul li:first-child a {
        border-left: 1px solid #dcdcdc;
        border-radius: 5px 0 0 5px; }
      .anchor_link ul li:last-child a {
        border-radius: 0 5px 5px 0; }
    .anchor_link ul.column02 li {
      width: 50%; }
    .anchor_link ul.column03 li {
      width: 33%; }
    .anchor_link ul.column04 li {
      width: 50%; }
      .anchor_link ul.column04 li:first-child a {
        border-radius: 5px 0 0 0; }
      .anchor_link ul.column04 li:nth-child(2) a {
        border-radius: 0 5px 0 0; }
      .anchor_link ul.column04 li:nth-last-child(2) a {
        border-left: 1px solid #dcdcdc;
        border-top: none;
        border-radius: 0 0 0 5px; }
      .anchor_link ul.column04 li:last-child a {
        border-top: none;
        border-radius: 0 0 5px 0; }

/* .price_list
----------------------------------------------------------- */
.price_list .box {
  margin: 10px 0;
  padding: 8px 10px;
  background: #fffafa;
  border: 2px solid #ffc7c7; }
  .price_list .box p .text_ex_large,
  .price_list .box p .text_middle {
    font-size: 1.2rem; }

.price_list table {
  margin: 0 0 5px;
  font-size: 0.8rem;
  line-height: 1.4; }
  .price_list table th,
  .price_list table td {
    padding: 5px 2px;
    text-align: center; }
  .price_list table thead th {
    background: #ddedff; }
  .price_list table tbody th {
    background: #f6f6f6; }
  .price_list table tbody .total {
    color: #00358e; }

.price_list .cartjs_box {
  border: none !important; }
  .price_list .cartjs_box .cartjs_product_table {
    display: none; }
  .price_list .cartjs_box .cartjs_cart_in input[type="submit"] {
    width: 100% !important;
    margin: 0 !important;
    padding: 5px !important;
    background: -webkit-linear-gradient(top, #fff 0%, #ededed 100%) !important;
    border: 1px solid #dcdcdc !important;
    border-radius: 2px !important;
    box-shadow: none !important;
    color: #333 !important;
    font-size: 0.8rem !important;
    font-weight: normal !important;
    text-shadow: none !important; }

.price_list .note {
  display: table;
  margin: 10px 0 0; }
  .price_list .note li {
    display: table-row; }
    .price_list .note li > span:first-child {
      display: table-cell;
      padding: 0 .5em 0 0; }

/* .feature_block
----------------------------------------------------------- */
.feature_block {
  padding: 15px;
  background: url(/common/img/common/bg_texture03.png) 0 0 repeat; }
  .feature_block > * {
    background: #fff; }
  .feature_block > *:not(:last-child) {
    margin: 0 0 15px; }
  .feature_block .lead {
    position: relative;
    min-height: 160px;
    padding: 15px; }
    .feature_block .lead div {
      display: flex;
      align-items: center;
      margin: -30px 0 0 -30px; }
      .feature_block .lead div h2 {
        position: relative;
        width: 25%; }
      .feature_block .lead div p.lead01 {
        margin: 15px 0 0 10px;
        color: #2f6eb4;
        font-size: 1.4rem;
        font-weight: bold;
        line-height: 1.4; }
    .feature_block .lead p.lead02 {
      margin: 5px 0 15px;
      padding: 5px 0;
      border-top: 1px solid #2f6eb4;
      border-bottom: 1px solid #2f6eb4;
      color: #2f6eb4;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.4; }
  .feature_block section div {
    display: flex;
    align-items: center;
    background: #2167aa; }
    .feature_block section div h3 {
      width: 36%;
      padding: 0 0 0 10px;
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.4; }
    .feature_block section div img {
      width: 64%;
      height: auto; }
  .feature_block section p {
    padding: 10px 15px; }
  .feature_block section.transparency {
    position: relative; }
    .feature_block section.transparency div + img {
      position: absolute;
      top: 0;
      right: 0;
      width: 64%; }
    .feature_block section.transparency p {
      padding-top: 8%; }

/* .pager
----------------------------------------------------------- */
.pager {
  margin: 20px 0; }
  .pager ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .pager ul li {
      margin: 0 3px 10px; }
      .pager ul li > * {
        display: block;
        padding: 8px 12px;
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        text-decoration: none;
        white-space: nowrap;
        line-height: 1.4;
        cursor: pointer; }
        .pager ul li > *:hover, .pager ul li > *.current {
          background: #2f6eb4;
          border: 1px solid #2f6eb4;
          color: #fff;
          opacity: 1; }

/* .entry_pager（詳細ページ用）
----------------------------------------------------------- */
.entry_pager {
  position: relative;
  margin: 30px 0 35px;
  padding: 15px 0 0;
  border-top: 1px dotted #dcdcdc; }
  .entry_pager li {
    text-align: center; }
    .entry_pager li.prev, .entry_pager li.next {
      position: absolute;
      top: 15px;
      left: 0; }
    .entry_pager li.next {
      left: inherit;
      right: 0; }
    .entry_pager li a {
      display: inline-block;
      padding: 8px 15px;
      background: #2f6eb4;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      white-space: nowrap; }

/* .img_box
----------------------------------------------------------- */
.img_box {
  margin: 0 0 20px; }
  .img_box .floatL,
  .img_box .floatR {
    margin: 0 0 15px; }

/* ============================================================ */
/* side */
/* ============================================================ */
#side {
  margin: 20px 0 0;
  line-height: 1.6; }

/* #side_pickup
----------------------------------------------------------- */
#side_pickup {
  margin: 0 0 20px;
  padding: 10px;
  background: url(/common/img/common/bg_texture02.png) 0 0 repeat; }
  #side_pickup section {
    background: #fff; }
    #side_pickup section:not(:first-child) {
      margin: 10px 0 0; }
    #side_pickup section [class^="btn"] {
      display: block; }
    #side_pickup section:not(.shipping_day):not(.price_list) {
      padding: 1px; }
      #side_pickup section:not(.shipping_day):not(.price_list) h2 {
        position: relative;
        margin: 0 0 10px;
        padding: 8px 10px;
        background: #afd3fb;
        color: #124985;
        font-size: 1.3rem; }
        #side_pickup section:not(.shipping_day):not(.price_list) h2::after {
          position: absolute;
          left: 0;
          right: 0;
          bottom: -2px;
          content: "";
          border-bottom: 1px dotted #a0b187; }
      #side_pickup section:not(.shipping_day):not(.price_list) div:not(.al_center) {
        margin: 0 10px;
        overflow: hidden; }
        #side_pickup section:not(.shipping_day):not(.price_list) div:not(.al_center) span {
          position: relative;
          float: left;
          display: block;
          width: 30px;
          height: 30px;
          margin: 0 10px 0 0;
          border: 2px solid #d8eaff;
          border-radius: 3px;
          text-align: center; }
          #side_pickup section:not(.shipping_day):not(.price_list) div:not(.al_center) span::before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            border: 1px solid #afd3fb; }
          #side_pickup section:not(.shipping_day):not(.price_list) div:not(.al_center) span i {
            color: #134884;
            font-size: 2.0rem;
            line-height: 26px; }
      #side_pickup section:not(.shipping_day):not(.price_list) div.al_center {
        margin: 10px; }
  #side_pickup .shipping_day,
  #side_pickup .price_list {
    padding: 2px 0 0; }
    #side_pickup .shipping_day h2,
    #side_pickup .price_list h2 {
      padding: 5px;
      background: #2f6eb4;
      color: #fff;
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center; }
    #side_pickup .shipping_day h3,
    #side_pickup .price_list h3 {
      font-size: 1.4rem;
      text-align: center;
      background: #F3F0D0;
      padding: 5px 0;
      font-weight: bold; }
    #side_pickup .shipping_day div,
    #side_pickup .price_list div {
      padding: 10px 12px;
      background: url(/common/img/common/bg_texture01.png) 0 0 repeat;
      border-top: 1px solid #e0e0e0; }
      #side_pickup .shipping_day div p,
      #side_pickup .price_list div p {
        margin: 0 0 8px; }
      #side_pickup .shipping_day div #view_cart,
      #side_pickup .price_list div #view_cart {
        margin-top: 10px;
        display: block; }
        #side_pickup .shipping_day div #view_cart .cartjs_box,
        #side_pickup .price_list div #view_cart .cartjs_box {
          display: none; }
  #side_pickup .repeat_order #view_cart .cartjs_box {
    display: none; }

/* #side_column / #side_voice
----------------------------------------------------------- */
#side_column,
#side_voice {
  margin: 0 0 20px; }
  #side_column h2,
  #side_voice h2 {
    padding: 12px 14px;
    background: linear-gradient(to bottom, #fefffe 0%, #edeeed 100%);
    border-top: 4px solid #3877bd;
    border-bottom: 1px solid #dcdcdc;
    color: #124985;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.4; }
  #side_column ul li,
  #side_voice ul li {
    border-bottom: 1px dotted #dcdcdc; }
    #side_column ul li a,
    #side_voice ul li a {
      display: block;
      padding: 10px; }

/* #side_column */
#side_column {
  border-bottom: 1px solid #dcdcdc; }
  #side_column ul li:nth-child(2n) {
    background: #e6f1fe; }
  #side_column ul li a time {
    color: #333;
    font-size: 1.0rem;
    font-weight: bold; }
  #side_column ul li a p {
    position: relative;
    padding: 0 0 0 8px; }
    #side_column ul li a p i {
      position: absolute;
      top: 3px;
      left: 2px;
      color: #ffa619; }
  #side_column div {
    padding: 10px 0; }

/* #side_voice */
#side_voice ul li:last-child {
  border-bottom: 1px solid #dcdcdc; }

#side_voice ul li a {
  overflow: hidden; }
  #side_voice ul li a img {
    float: left;
    width: 12%;
    margin: 0 10px 0 0; }
  #side_voice ul li a p {
    line-height: 1.4;
    overflow: hidden; }
    #side_voice ul li a p:first-of-type {
      white-space: nowrap;
      text-overflow: ellipsis; }
      #side_voice ul li a p:first-of-type i {
        margin: 0 5px 0 0; }
    #side_voice ul li a p:last-of-type {
      margin-top: 5px;
      color: #333;
      font-size: 1.0rem;
      text-align: right; }

/* #side_postage
----------------------------------------------------------- */
#side_postage {
  margin: 0 10px; }
  #side_postage h2 {
    position: relative;
    margin: 0 0 10px;
    padding: 10px;
    background: #2f6eb4;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold; }
    #side_postage h2::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
      content: "";
      border-bottom: 1px dotted #a0b187; }
  #side_postage table {
    margin: 0 0 10px; }
    #side_postage table th {
      background: #ddedff;
      text-align: center; }

/* ============================================================ */
/* footer */
/* ============================================================ */
footer {
  margin: 40px 0 0; }
  footer #footer_nav ul {
    display: flex;
    justify-content: center;
    margin: 0 20px 12px;
    font-size: 1.0rem; }
    footer #footer_nav ul li:nth-child(1) {
      flex-basis: 25%; }
    footer #footer_nav ul li:nth-child(2) {
      flex-basis: 30%; }
    footer #footer_nav ul li:nth-child(3) {
      flex-basis: 45%; }
    footer #footer_nav ul li:not(:first-child) {
      border-left: 1px dotted #b1b1b1; }
    footer #footer_nav ul li a {
      display: block;
      color: #333;
      text-align: center; }
      footer #footer_nav ul li a i {
        margin: 0 2px 0 0;
        color: #ffa619; }
  footer .pagetop a {
    display: block;
    padding: 5px 0;
    background: #3f3f3f;
    color: #fff;
    text-align: center; }
    footer .pagetop a i {
      margin: 0 0.5em 0 0; }
  footer .copyright {
    padding: 10px 0;
    background: #e2f0ff;
    font-size: 0.8rem;
    text-align: center; }

/* ============================================================ */
/* index.html */
/* ============================================================ */
#index {
  /* #visual
	----------------------------------------------------------- */
  /* .btn_inquiry
	----------------------------------------------------------- */
  /* #stock
	----------------------------------------------------------- */
  /* #feature
	----------------------------------------------------------- */
  /* #inquiry
	----------------------------------------------------------- */
  /* #reassurance
	----------------------------------------------------------- */
  /* #order
	----------------------------------------------------------- */
  /* #worry
	----------------------------------------------------------- */ }
  #index .index_ttl {
    display: flex;
    align-items: center;
    margin: 0 0 10px;
    font-size: 1.6rem;
    font-weight: bold; }
    #index .index_ttl i {
      margin: 0 5px 0 0;
      color: #2f6eb4;
      font-size: 2.0rem; }
  #index #visual {
    margin: 10px; }
  #index .btn_inquiry .btn_red {
    display: block;
    padding: 6px 0;
    line-height: 1.1; }
    #index .btn_inquiry .btn_red p:last-child {
      font-size: 2.0rem;
      font-weight: bold; }
      #index .btn_inquiry .btn_red p:last-child .icon-freedial {
        position: relative;
        top: 4px;
        margin: 0 5px 0 0;
        font-size: 2.8rem;
        line-height: .8; }
        #index .btn_inquiry .btn_red p:last-child .icon-freedial .path3::before {
          color: #0fa639; }
  #index #stock {
    position: relative;
    margin: 15px 0; }
    #index #stock a {
      position: absolute;
      left: 12px;
      right: 12px;
      display: block; }
      #index #stock a:nth-of-type(1) {
        top: 48%; }
      #index #stock a:nth-of-type(2) {
        top: 73%; }
  #index #feature {
    margin: 15px 0; }
  #index #inquiry {
    position: relative; }
    #index #inquiry .btn_red {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 15px;
      right: 15px;
      top: 72%;
      bottom: 10%;
      padding: 0; }
  #index #reassurance > .text_ex_large {
    font-size: 1.2rem; }
  #index #reassurance .column div {
    margin: 20px 0 0;
    overflow: hidden; }
    #index #reassurance .column div img {
      float: right;
      width: 22.5%;
      margin: 0 0 0 12px; }
    #index #reassurance .column div p:not(:last-child) {
      margin: 0 0 10px; }
  #index #order .ttl03 {
    margin-top: 15px; }
  #index #worry ul li {
    border-bottom: 1px dotted #b1b1b1;
    line-height: 1.6; }
    #index #worry ul li a {
      display: block;
      padding: 10px 0;
      overflow: hidden; }
      #index #worry ul li a img {
        float: left;
        width: 21.6%;
        margin: 0 15px 0 0; }
      #index #worry ul li a h3 {
        position: relative;
        padding: 0 0 0 8px;
        overflow: hidden; }
        #index #worry ul li a h3 i {
          position: absolute;
          top: 3px;
          left: 1px; }
      #index #worry ul li a p {
        color: #333;
        font-size: 1.0rem; }
    #index #worry ul li:first-child a {
      padding-top: 0; }
    #index #worry ul li:last-child {
      border-bottom: none; }
      #index #worry ul li:last-child a {
        padding-bottom: 0; }

/* ============================================================ */
/* /product/ */
/* ============================================================ */
#product_index {
  /* #cheap
	----------------------------------------------------------- */ }
  #product_index #difference div,
  #product_index #cheap div {
    padding: 15px;
    background: url(/common/img/common/bg_texture03.png) 0 0 repeat; }
    #product_index #difference div h3,
    #product_index #cheap div h3 {
      position: relative;
      padding: 10px 0;
      background: #fff;
      color: #2f6eb4;
      font-size: 1.4rem;
      font-weight: bold;
      text-align: center; }
      #product_index #difference div h3::before,
      #product_index #cheap div h3::before {
        position: absolute;
        top: 50%;
        left: -15px;
        display: block;
        content: "";
        width: 80px;
        height: 80px;
        margin: -40px 0 0;
        background: url(/common/img/product/ttl_different.png) 0 0 no-repeat;
        background-size: 80px; }
    #product_index #difference div ol,
    #product_index #cheap div ol {
      display: flex;
      flex-direction: column; }
      #product_index #difference div ol li,
      #product_index #cheap div ol li {
        position: relative;
        margin: 20px 0 0 0;
        padding: 10px;
        background: #fff;
        counter-increment: number; }
        #product_index #difference div ol li::before,
        #product_index #cheap div ol li::before {
          position: absolute;
          top: -14px;
          left: 50%;
          z-index: 1;
          margin: 0 0 0 -15px;
          display: block;
          content: counter(number);
          width: 30px;
          height: 30px;
          background: #fff;
          border: 2px solid #2f6eb4;
          border-radius: 50%;
          box-sizing: border-box;
          color: #2f6eb4;
          font-size: 1.4rem;
          font-weight: bold;
          text-align: center;
          line-height: 26px; }
        #product_index #difference div ol li h4,
        #product_index #cheap div ol li h4 {
          align-items: center;
          position: relative;
          z-index: 5;
          margin: 0 0 10px;
          padding: 10px 0;
          background: #2f6eb4;
          color: #fff;
          font-size: 1.3rem;
          font-weight: bold;
          text-align: center;
          line-height: 1.4; }
          #product_index #difference div ol li h4::after,
          #product_index #cheap div ol li h4::after {
            position: absolute;
            left: 50%;
            bottom: -8px;
            content: "";
            margin: 0 0 0 -8px;
            border-color: #2f6eb4 transparent transparent transparent;
            border-style: solid;
            border-width: 8px 8px 0; }
          #product_index #difference div ol li h4 span,
          #product_index #cheap div ol li h4 span {
            display: block;
            width: 100%; }
  #product_index #cheap div ol li:nth-child(2) {
    min-height: 170px;
    background: url(/common/img/product/bg_reason02.png) right bottom no-repeat #fff;
    background-size: 100px; }
    #product_index #cheap div ol li:nth-child(2) p {
      margin-right: 100px; }
  #product_index #cheap div ol li:nth-child(3) {
    min-height: 170px;
    background: url(/common/img/product/bg_reason03.png) right bottom no-repeat #fff;
    background-size: 100px; }
    #product_index #cheap div ol li:nth-child(3) p {
      margin-right: 100px; }
  #product_index #cheap div ol li strong {
    color: #2f6eb4;
    font-size: 1.4rem; }
  #product_index #cheap div ol li em {
    color: #2f6eb4; }

/* ============================================================ */
/* /result/ */
/* ============================================================ */
#result_index {
  /* #trading_performance
	----------------------------------------------------------- */
  /* #counterparty
	----------------------------------------------------------- */
  /* #voice
	----------------------------------------------------------- */ }
  #result_index #trading_performance section.row {
    margin-bottom: 0 !important; }
  #result_index #trading_performance .ttl03 i {
    position: absolute;
    top: 50%;
    right: 10px;
    margin: -0.5em 0 0;
    color: #2f6eb4;
    font-size: 1.1rem;
    line-height: 1;
    transition: all 0.3s ease-out; }
  #result_index #trading_performance .ttl03.active i {
    transform: rotate(180deg); }
  #result_index #trading_performance div {
    display: none; }
    #result_index #trading_performance div .trading_performance_list {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin: -8px 0 30px;
      font-size: 1.0rem;
      line-height: 1.6; }
      #result_index #trading_performance div .trading_performance_list::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        border-bottom: 1px dotted #dcdcdc; }
      #result_index #trading_performance div .trading_performance_list li {
        position: relative;
        width: 50%;
        padding: 8px 0 8px 12px;
        border-bottom: 1px dotted #dcdcdc; }
        #result_index #trading_performance div .trading_performance_list li::before {
          position: absolute;
          top: 14px;
          left: 4px;
          display: block;
          content: "";
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background: #2f6eb4; }
  #result_index #counterparty ul {
    display: flex;
    flex-wrap: wrap; }
    #result_index #counterparty ul li {
      position: relative;
      width: 50%;
      padding: 10px 0 10px 20px; }
      #result_index #counterparty ul li:nth-child(2n) {
        border-left: 1px solid #fff; }
      #result_index #counterparty ul li:nth-child(4n+1), #result_index #counterparty ul li:nth-child(4n+2) {
        background: #fafafa; }
      #result_index #counterparty ul li::before {
        position: absolute;
        top: 20px;
        left: 12px;
        display: block;
        content: "";
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #2f6eb4; }
  #result_index #voice ul li {
    padding: 20px 0;
    border-bottom: 1px dotted #dcdcdc;
    overflow: hidden; }
    #result_index #voice ul li:last-child {
      border-bottom: none; }
    #result_index #voice ul li img {
      display: block;
      width: 80%;
      margin: 0 auto; }
    #result_index #voice ul li p {
      margin: 10px 0 0; }
      #result_index #voice ul li p.customer {
        text-align: right; }

/* ============================================================ */
/* /faq/ */
/* ============================================================ */
#faq_index .row dl dt {
  position: relative;
  margin: 10px 0 0;
  padding: 10px 20px 10px 30px;
  background: #f3f9fd;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  line-height: 1.4; }
  #faq_index .row dl dt i {
    position: absolute;
    top: 50%;
    right: 10px;
    margin: -0.5em 0 0;
    color: #2f6eb4;
    font-size: 1.1rem;
    line-height: 1;
    transition: all 0.3s ease-out; }
  #faq_index .row dl dt::before {
    position: absolute;
    top: 50%;
    left: 10px;
    content: "Q";
    margin: -0.5em 10px 0 0;
    color: #2f6eb4;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1; }
  #faq_index .row dl dt.active {
    border-radius: 5px 5px 0 0; }
    #faq_index .row dl dt.active i {
      transform: rotate(180deg); }

#faq_index .row dl dd {
  display: none;
  position: relative;
  margin: 0 0 25px;
  padding: 10px 10px 10px 35px;
  border: 1px solid #dcdcdc;
  border-top: none;
  border-radius: 0 0 5px 5px; }
  #faq_index .row dl dd::before {
    position: absolute;
    top: 15px;
    left: 12px;
    content: "A";
    margin: 0 10px 0 0;
    color: #f77010;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1; }
  #faq_index .row dl dd h3 {
    margin: 0 0 5px;
    color: #2f6eb4;
    font-size: 1.5rem;
    font-weight: bold; }

/* ============================================================ */
/* /reassure/ */
/* ============================================================ */
#reassure_index {
  /* #purchase
	----------------------------------------------------------- */
  /* #order
	----------------------------------------------------------- */ }
  #reassure_index #purchase img {
    float: right;
    width: 35%;
    margin: 0 0 0 15px; }
  #reassure_index #order {
    /*----- #lead -----*/
    /*----- #tel -----*/
    /*----- #web -----*/ }
    #reassure_index #order #lead em span {
      color: #2f6eb4; }
    #reassure_index #order #tel ol li {
      position: relative;
      padding: 15px 10px 15px 42px;
      counter-increment: flow;
      border: 1px solid #dcdcdc;
      border-radius: 5px; }
      #reassure_index #order #tel ol li::before {
        position: absolute;
        top: 15px;
        left: 12px;
        display: block;
        content: counter(flow);
        width: 20px;
        height: 20px;
        background: #2f6eb4;
        color: #fff;
        font-size: 1.3rem;
        font-weight: bold;
        text-align: center;
        line-height: 20px; }
      #reassure_index #order #tel ol li:not(:last-child) {
        margin: 0 0 24px; }
        #reassure_index #order #tel ol li:not(:last-child)::after {
          position: absolute;
          left: 50%;
          bottom: -18px;
          content: "";
          margin: 0 0 0 -14px;
          border-color: #dbdbdb transparent transparent;
          border-style: solid;
          border-width: 10px 14px 0; }
    #reassure_index #order #web .btn_red {
      width: 100%;
      font-size: 1.4rem;
      font-weight: bold; }

@media screen and (min-width: 414px) {
  #reassure_index #web .btn_red br {
    display: none; } }

/* ============================================================ */
/* /sample/ */
/* ============================================================ */
.sample .row .alert_block {
  margin: 0 0 30px; }

.sample .row .form_table .note {
  margin: 5px 0 0;
  font-size: 1.3rem; }

.sample .row .error {
  margin: 10px 0 -5px !important;
  color: #cd1818;
  font-weight: bold;
  font-size: 1.3rem; }

.sample .row .al_center {
  margin: 20px 0; }
  .sample .row .al_center .btn,
  .sample .row .al_center .btn_submit {
    width: 80%;
    height: 50px;
    margin: 4px 0;
    padding: 0;
    font-size: 1.4rem;
    line-height: 50px; }

/* ============================================================ */
/* #sample_index */
/* ============================================================ */
#sample_index .triggered p {
  margin: 0 0 25px 25px; }
  #sample_index .triggered p:last-child {
    margin-bottom: 0; }

#sample_index .triggered input[type="text"] {
  width: 60%; }

/* ============================================================ */
/* /order/ */
/* ============================================================ */
.order .row .alert_block {
  margin: 0 0 15px; }

.order .row .form_table .note {
  margin: 5px 0 0;
  font-size: 1.3rem; }

.order .row .error {
  margin: 10px 0 -5px !important;
  color: #cd1818;
  font-weight: bold;
  font-size: 1.3rem; }

.order .row .al_center {
  margin: 20px 0; }
  .order .row .al_center .btn,
  .order .row .al_center .btn_submit {
    width: 80%;
    height: 50px;
    margin: 4px 0;
    padding: 0;
    font-size: 1.4rem;
    line-height: 50px; }

/* ============================================================ */
/* #order_index */
/* ============================================================ */
#order_index .radio {
  display: inline-block; }
  #order_index .radio:nth-child(2n) {
    width: 35%; }
  #order_index .radio:nth-child(2n+1) {
    width: 55%; }

#order_index .note {
  margin: 10px 0 0; }

#order_index .attention {
  height: 200px;
  margin: 0 0 20px;
  padding: 10px;
  border: 1px solid #dcdcdc;
  overflow: auto; }

#order_index #postage table {
  margin: 0 0 10px; }
  #order_index #postage table tbody th {
    background: #f6f6f6; }
  #order_index #postage table tbody td {
    text-align: center; }

#order_index #postage .alert_block span {
  color: #cd1818; }

/* ============================================================ */
/* /inquiry/ */
/* ============================================================ */
.inquiry .row .alert_block {
  margin: 0 0 30px; }

.inquiry .row .form_table .note {
  margin: 5px 0 0;
  font-size: 1.3rem; }

.inquiry .row .error {
  margin: 10px 0 -5px !important;
  color: #cd1818;
  font-weight: bold;
  font-size: 1.3rem; }

.inquiry .row .al_center {
  margin: 20px 0; }
  .inquiry .row .al_center .btn,
  .inquiry .row .al_center .btn_submit {
    width: 80%;
    height: 50px;
    margin: 4px 0;
    padding: 0;
    font-size: 1.4rem;
    line-height: 50px; }

/* ============================================================ */
/* #column_index */
/* ============================================================ */
#column_index .ttl02 {
  margin-bottom: 0; }

#column_index .column_list li {
  border-bottom: 1px dotted #dcdcdc; }
  #column_index .column_list li:nth-child(2n) {
    background: #e6f1fe; }
  #column_index .column_list li a {
    display: block;
    padding: 10px; }
    #column_index .column_list li a time {
      color: #333;
      font-size: 1.0rem;
      font-weight: bold; }
    #column_index .column_list li a p {
      position: relative;
      padding: 0 0 0 8px; }
      #column_index .column_list li a p i {
        position: absolute;
        top: 3px;
        left: 2px;
        color: #ffa619; }

/* ============================================================ */
/* #column_detail */
/* ============================================================ */
#column_detail .entry_body time {
  display: block;
  margin: -5px 5px 0;
  font-size: 1.1rem;
  font-weight: bold; }

#column_detail .entry_body .entry_block {
  margin: 15px 0 0; }
  #column_detail .entry_body .entry_block > *:not(.row) {
    margin: 0 0 15px; }
  #column_detail .entry_body .entry_block strong {
    color: #333; }
  #column_detail .entry_body .entry_block table .al_center {
    display: table-cell; }
  #column_detail .entry_body .entry_block table tbody th {
    background: #f6f6f6; }
  #column_detail .entry_body .entry_block .text_b {
    font-weight: bold; }
  #column_detail .entry_body .entry_block #paste2 .img_box img {
    display: block;
    width: 60%;
    margin-left: auto;
    margin-right: auto; }
  #column_detail .entry_body .entry_block #test th.a4 {
    background: #dcedc9; }
  #column_detail .entry_body .entry_block #test td.a4 {
    background: #eaf4de; }
  #column_detail .entry_body .entry_block #test th.kaku2 {
    background: #ffecb4; }
  #column_detail .entry_body .entry_block #test td.kaku2 {
    background: #fff4d1; }
  #column_detail .entry_body .entry_block #test .reaction tbody th {
    width: 28%; }
  #column_detail .entry_body .entry_block #test .box {
    padding: 10px;
    background: #e6f1fe;
    border: 2px solid #ddedff; }
    #column_detail .entry_body .entry_block #test .box strong {
      color: #084385; }
  #column_detail .entry_body .entry_block #test .result {
    padding: 10px;
    background: #e6f1fe;
    border-radius: 4px; }
    #column_detail .entry_body .entry_block #test .result .flex {
      display: flex;
      flex-direction: column; }
      #column_detail .entry_body .entry_block #test .result .flex div {
        margin: 0 0 10px;
        padding: 10px;
        background: #fff;
        border-radius: 4px; }
    #column_detail .entry_body .entry_block #test .result > table th {
      width: 20%; }
    #column_detail .entry_body .entry_block #test .result > table td {
      background: #fff; }
  #column_detail .entry_body .entry_block #test .comment {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 15px 0; }
    #column_detail .entry_body .entry_block #test .comment span {
      width: 20%; }
    #column_detail .entry_body .entry_block #test .comment div {
      position: relative;
      margin: 0 20px 0 0;
      padding: 15px 20px;
      background: #fafafa;
      border-radius: 4px;
      flex: 1; }
      #column_detail .entry_body .entry_block #test .comment div::after {
        position: absolute;
        top: 50%;
        right: -12px;
        z-index: 5;
        content: "";
        margin: -5px 0 0;
        border-color: transparent transparent transparent #fafafa;
        border-style: solid;
        border-width: 10px 0 10px 12px; }
  #column_detail .entry_body .entry_block #size table th.dmft {
    background: #ffecb4; }
  #column_detail .entry_body .entry_block #size table td.dmft {
    background: #fff4d1; }
